<template>
  <b-button v-bind="$attrs" v-on="$listeners">
    <b-spinner v-if="busyState" small label="Busy"></b-spinner>
    <slot />
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';

export default {
  components: { BButton, BSpinner },
  props: {
    busyState: { type: Boolean, default: false },
  },
};
</script>