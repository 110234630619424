/**
 * Returns spaced number with n digits after comma.
 *
 * @param {string | number} inputNumber
 * @param {number} toFixedValue The number of digits after comma. Default: 2.
 */
export default function toFixedAndSpace(inputNumber, toFixedValue = 2) {
  return inputNumber !== null && inputNumber !== undefined && inputNumber !== ''
    ? String((+inputNumber).toFixed(toFixedValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : '';
}
