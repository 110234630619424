<template>
  <div class="d-flex align-items-center">
    <h1 class="h3 mr-3">{{ title }}</h1>
    <small v-if="entries && per" tabindex="-1" class="form-text text-muted">
      {{ $t('title.showing') }} {{ parseInt(entries) >= parseInt(per) ? per : entries }} {{ $t('title.of') }} {{ entries }} {{ $t('title.entries') }}
    </small>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: [String, Number], default: undefined },
    per: { type: [String, Number], default: undefined },
    entries: { type: [String, Number], default: undefined },
  },
};
</script>
