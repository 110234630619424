<template>
  <div class="wrapper-default-page pb-0" :class="isThemeHeader === 'true' ? 'white-color' : 'bg-white'">
    <div class="d-flex flex-wrap py-1 border-bottom automatics-page-header">
      <VocabularyTitle :title="$t('sideBar.automaticBooking')" :per="paginationData.per_page" :entries="paginationData.total" />
      <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
      <!--Search-->
      <div class="d-flex justify-content-between gap-3 my-3 w-100">
        <div class="d-flex gap-3">
          <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

          <MultiSelect
            v-model="selectedChannel"
            label="name"
            track-by="id"
            :options="filterChannelsList || []"
            class="w-250px"
            :allow-empty="false"
            :placeholder="$t('table.selectChannel')"
            @input="pageClick(1)"
          ></MultiSelect>
          <datepicker-wrapper id="filter-day-from-datepicker" v-model="filters.date_from" required style="width: 250px" @input="selectDateFrom" />
          <datepicker-wrapper
            id="filter-day-to-datepicker"
            v-model="filters.date_to"
            required
            :min="filters.date_from"
            :max="maxPeriodDate"
            style="width: 250px"
            :disabled="!filters.date_from"
            @input="pageClick(1)"
          />
        </div>

        <search-input v-model="searchElements" @searchTable="pageClick(1)" />
      </div>
      <!--Search-->
    </div>

    <!--TABLE-->
    <b-table
      v-if="tableData && automaticMediaPlansStatus !== 'loading'"
      id="tableAutomaton"
      ref="selectableTableAutomaton"
      key="mediaplan_id"
      :fields="[
        { key: 'agency_name', label: $t('table.agency') },
        { key: 'project_name', label: $t('table.project') },
        { key: 'order_name', label: $t('table.order') },
        { key: 'mediaplan_name', label: $t('table.mediaPlan') },
        { key: 'channel_name', label: $t('channelModal.channel') },
        { key: 'period', label: $t('table.period') },
        isCurrentUserChannelManager ? undefined : { key: 'commitment_budget', label: $t('table.commitment') },
        isCurrentUserChannelManager ? undefined : { key: 'plan_fact_budget', label: `${$t('table.plan')}+${$t('table.fact')}` },
        { key: 'current_wgrp_prime', label: 'Prime' },
        { key: 'current_wgrp_off_prime', label: 'Off-Prime' },
        { key: 'deviation', label: `${$t('table.delta')}, %` },
        { key: 'spot_count_prime', label: `${$t('table.spotsGenitive')} Prime` },
        { key: 'spot_count_off_prime', label: `${$t('table.spotsGenitive')} Off-Prime` },
        isCurrentUserChannelManager ? undefined : { key: 'cpp_prime', label: 'CPP Prime' },
        isCurrentUserChannelManager ? undefined : { key: 'cpp_off_prime', label: 'CPP Off-prime' },
      ]"
      :items="tableData"
      select-mode="multi"
      small
      selectable
      responsive
      :table-class="[isThemeHeader === 'true' ? 'white-color' : 'dark-color', 'sticky-headers']"
      @row-selected="onRowSelected"
    >
      <template #head(agency_name)="data">
        <div class="resizable">
          {{ data.label }}
        </div>
      </template>
      <template #head(project_name)="data">
        <div class="resizable">
          {{ data.label }}
        </div>
      </template>
      <template #head(order_name)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'order_name,'" @click.stop="sortTable('order_name,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(mediaplan_name)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'mediaplan_name,'" @click.stop="sortTable('mediaplan_name,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(channel_name)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'channel_name,'" @click.stop="sortTable('channel_name,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(period)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'date_from,'" @click.stop="sortTable('date_from,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(commitment_budget)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'commitment_budget,'" @click.stop="sortTable('commitment_budget,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(plan_fact_budget)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'plan_fact_budget,'" @click.stop="sortTable('plan_fact_budget,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(current_wgrp_prime)="data">
        <!-- <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'current_wgrp_prime,'" @click.stop="sortTable('current_wgrp_prime,', $event)" /> -->
        <abbr :title="`${$t('reports.current')} wGRP (${$t('table.target')} wGRP)`">{{ data.label }}</abbr>
        <!-- </div> -->
      </template>
      <template #head(current_wgrp_off_prime)="data">
        <!-- <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'current_wgrp_off_prime,'" @click.stop="sortTable('current_wgrp_off_prime,', $event)" /> -->
        <abbr :title="`${$t('reports.current')} wGRP (${$t('table.target')} wGRP)`">{{ data.label }}</abbr>
        <!-- </div> -->
      </template>
      <template #head(deviation)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'deviation,'" @click.stop="sortTable('deviation,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(spot_count_prime)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'spot_count_prime,'" @click.stop="sortTable('spot_count_prime,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(spot_count_off_prime)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'spot_count_off_prime,'" @click.stop="sortTable('spot_count_off_prime,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(cpp_prime)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'cpp_prime,'" @click.stop="sortTable('cpp_prime,', $event)" />
          {{ data.label }}
        </div>
      </template>
      <template #head(cpp_off_prime)="data">
        <div class="resizable">
          <sort-arrows :sort-string="sortString" :column="'cpp_off_prime,'" @click.stop="sortTable('cpp_off_prime,', $event)" />
          {{ data.label }}
        </div>
      </template>

      <template #cell(period)="data"> {{ data.item.date_from | convertDate }}-{{ data.item.date_to | convertDate }} </template>
      <template #cell(commitment_budget)="data">
        {{ data.item.commitment_budget | toFixedAndSpace }}
      </template>
      <template #cell(plan_fact_budget)="data">
        {{ data.item.plan_fact_budget | toFixedAndSpace }}
      </template>
      <template #cell(current_wgrp_prime)="data">
        {{ data.item.current_wgrp_prime | toFixedAndSpace }} <br />({{ data.item.target_prime_wgrp | toFixedAndSpace }})
      </template>
      <template #cell(current_wgrp_off_prime)="data">
        {{ data.item.current_wgrp_off_prime | toFixedAndSpace }} <br />({{ data.item.target_off_prime_wgrp | toFixedAndSpace }})
      </template>
      <template #cell(deviation)="data">
        {{ (+data.item.deviation).toFixed(2) }}
      </template>
      <template #cell(spot_count_prime)="data">
        {{ data.item.spot_count_prime }}
      </template>
      <template #cell(spot_count_off_prime)="data">
        {{ data.item.spot_count_off_prime }}
      </template>
      <template #cell(cpp_prime)="data">
        {{ data.item.cpp_prime | toFixedAndSpace }}
      </template>
      <template #cell(cpp_off_prime)="data">
        {{ data.item.cpp_off_prime | toFixedAndSpace }}
      </template>
    </b-table>
    <div v-if="automaticMediaPlansStatus !== 'success'">
      <SpinnerLoader :loading="automaticMediaPlansStatus" />
    </div>
    <template v-if="automaticMediaPlansStatus !== 'loading'">
      <div v-if="!(selectedChannel && filters.date_from && filters.date_to)" class="d-flex w-100 justify-content-center">
        {{ $t('table.selectChannelAndDatesFirst') }}
      </div>
      <div v-else-if="!(tableData && tableData.length)" class="d-flex w-100 justify-content-center">
        {{ $t('table.noContent') }}
      </div>
    </template>
    <!--TABLE-->

    <!--FOOTER-->
    <div class="input-pagin">
      <div class="d-flex justify-content-between gap-3">
        <b-button @click="$router.go(-1)">
          {{ $t('broadcastSideBar.exit') }}
        </b-button>

        <BButtonEnh v-if="canRunAutomaton" v-b-modal.modal-automatic variant="primary" :disabled="isRunAutomatonsDisabled || isRunning" :busy-state="isRunning">
          {{ $t('table.runAutomaton') }}
        </BButtonEnh>
        <ModalAutomatic
          :min-date="filters.date_from"
          :max-date="filters.date_to"
          :start-date="runningStartDate"
          :end-date="runningEndDate"
          storage-key="automaticBookingRunSettings"
          @run-automaton="runAutomaton"
        />
      </div>
    </div>
    <!--FOOTER-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '@/components/PaginateWrapper';
import SearchDropdown from '@/components/SearchDropdown';
import SearchInput from '@/components/SearchInput';
import convertDate from '../filters/convertDate';
import sortTableMixin from '@/mixins/sortTable';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import { BTable } from 'bootstrap-vue';
import SortArrows from '../components/SortArrows';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import BButtonEnh from '@/components/BButtonEnh.vue';
import ModalAutomatic from '@/components/MediaPlansSummary/ModalAutomatic.vue';

export default {
  name: 'AutomaticBooking',
  components: {
    PaginateWrapper,
    SpinnerLoader,
    VocabularyTitle,
    MultiSelect,
    SearchDropdown,
    SearchInput,
    BTable,
    SortArrows,
    DatepickerWrapper,
    BButtonEnh,
    ModalAutomatic,
  },
  filters: {
    convertDate,
    toFixedAndSpace,
  },
  mixins: [sortTableMixin],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    channel_id: { type: [String, Number], default: undefined },
    date_from: { type: String, default: undefined },
    date_to: { type: String, default: undefined },
  },

  data() {
    return {
      selectedChannel: '',
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterColumn: 'name',
      filterPage: 1,
      selectedPerPage: '300',

      currentCommercial: null,
      modalName: null,
      isOpenModal: null,

      filterChannelsList: [],
      filterBrandsList: [],
      filters: {
        date_from: null,
        date_to: null,
      },
      selected: [],

      currentMediaPlan: null,
      automatonExecutionResult: null,
      isRunning: false,
      sortString: 'mediaplan_name,',
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      isLocale: 'isLocale',
      automaticMediaPlans: 'getAutomaticMediaPlans',
      automaticMediaPlansStatus: 'getAutomaticMediaPlansStatus',
      channelsList: 'getChannelsList',
      isCurrentUserChannelManager: 'getIsCurrentUserChannelManager',
    }),
    maxPeriodDate() {
      return this.filters.date_from ? new Date(+this.filters.date_from.slice(0, 4), +this.filters.date_from.slice(5, 7), 0) : undefined;
    },
    isRunAutomatonsDisabled() {
      return this.automaticMediaPlansStatus === 'success' && this.tableData?.length ? false : true;
    },
    canRunAutomaton() {
      return this.$checkPermissions('scope.automatic_start');
    },
    runningStartDate() {
      if (this.selected.length == 1) {
        return this.selected[0].date_from;
      }
      return this.filters.date_from;
    },
    runningEndDate() {
      if (this.selected.length == 1) {
        return this.selected[0].date_to;
      }
      return this.filters.date_to;
    },
  },
  watch: {
    automaticMediaPlans() {
      this.setTableData();
    },
  },

  created() {
    document.title = this.$i18n.t('sideBar.automaticBooking') + ' – OpenMediaLogic';
  },

  mounted() {
    this.getAutomaticMediaPlans();
  },
  destroyed() {
    this.$store.commit('clearAutomaticMediaPlans');
  },

  methods: {
    async paramsData() {
      this.tableData.length = 0;
      if (this.selectedChannel && this.filters.date_from && this.filters.date_to) {
        await this.$store.dispatch('GET_AUTOMATIC_MEDIA_PLANS', {
          page: +this.filterPage,
          per_page: +this.selectedPerPage,
          'filter[name]': this.searchElements ? this.searchElements : null,
          'filter[channel_id]': this.selectedChannel ? this.selectedChannel.id : null,
          'filter[date_from]': this.filters.date_from ? this.filters.date_from : null,
          'filter[date_to]': this.filters.date_to ? this.filters.date_to : null,
          sort: this.sortString,
        });
      }
      this.updateQuery();
    },

    updateQuery() {
      if (
        this.$route.query.q !== this.searchElements ||
        this.$route.query.on_page != this.filterPage ||
        this.$route.query.per_page != this.selectedPerPage ||
        this.$route.query.sort != this.sortString ||
        this.$route.query.channel_id != this.selectedChannel?.id ||
        this.$route.query.date_from != this.filters.date_from ||
        this.$route.query.date_to != this.filters.date_to
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            q: this.searchElements,
            on_page: this.filterPage,
            per_page: this.selectedPerPage,
            sort: this.sortString,
            date_from: this.filters.date_from,
            date_to: this.filters.date_to,
            channel_id: this.selectedChannel?.id,
          },
        });
      }
    },

    restoreFromQuery() {
      if (this.q) this.searchElements = this.q;
      if (this.on_page) this.filterPage = +this.on_page;
      if (this.per_page && this.per_page != '10') this.selectedPerPage = this.per_page;
      if (this.sort_mode) {
        this.sortString = this.sort_mode;
        if (this.sortString && this.sortString.slice(-1) !== ',') this.sortString += ',';
      }
      if (this.channel_id) this.selectedChannel = this.filterChannelsList.find((el) => el.id === +this.channel_id);
      if (this.date_from && /\d{4}-\d{2}-\d{2}/.test(this.date_from)) this.filters.date_from = this.date_from;
      if (this.date_to && /\d{4}-\d{2}-\d{2}/.test(this.date_to)) this.filters.date_to = this.date_to;
    },

    pageClick(page) {
      this.filterPage = page;
      this.$refs.selectableTableAutomaton?.clearSelected();
      this.selected = [];
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.automaticMediaPlans) {
        this.tableData = this.automaticMediaPlans.data;
        this.paginationData = this.automaticMediaPlans.pagination;
        if (this.filterPage > this.automaticMediaPlans.pagination.last_page) {
          this.filterPage = this.automaticMediaPlans.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    async getAutomaticMediaPlans() {
      await this.$store.dispatch('GET_CHANNELS', { per_page: 1000 });
      this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channelsList];
      this.restoreFromQuery();
      this.selectDateFrom(); // to run dates validation
    },

    selectDateFrom() {
      if (!this.filters.date_from) return;
      if (!this.filters.date_to || new Date(this.filters.date_from) > new Date(this.filters.date_to) || new Date(this.filters.date_to) > this.maxPeriodDate) {
        // Rewrite date TO if doesn't exist, it is lower than FROM or goes beyond max prop
        this.filters.date_to = new Date(Date.UTC(+this.filters.date_from.slice(0, 4), +this.filters.date_from.slice(5, 7), 0)).toISOString().slice(0, 10);
      }
      this.pageClick(1);
    },

    async runAutomaton(settings) {
      const formData = {
        automatics: [],
        settings: settings,
      };
      if (!this.selected.length) {
        // Run all automatons in current list after confirm
        const automatonsNumber = this.paginationData.per_page > this.paginationData.total ? this.paginationData.total : this.paginationData.per_page;
        const modalResult = await this.$bvModal
          .msgBoxConfirm(`${this.$i18n.t('table.confirmRunAllAutomatonList', [automatonsNumber])}`, {
            title: this.$i18n.t('table.runAutomaton'),
            size: 'sm',
            okTitle: this.$i18n.t('table.yes'),
            cancelTitle: this.$i18n.t('table.no'),
            autoFocusButton: 'ok',
            id: 'confirm-run-automatons-modal',
          })
          .catch((err) => {});
        if (!modalResult) return;
        formData.automatics = this.tableData.map((el) => +el.mediaplan_id);
        await this.postStartAutomatics(formData);
      } else {
        // Run only selected automatons
        formData.automatics = this.selected.map((el) => +el.mediaplan_id);
        await this.postStartAutomatics(formData);
      }
    },

    async postStartAutomatics(formData) {
      this.isRunning = true;
      await this.$store.dispatch('POST_MEDIA_PLANS_AUTOMATON_EXECUTE', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.paramsData();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      if (this.$refs.selectableTableAutomaton) this.$refs.selectableTableAutomaton.clearSelected();
      this.selected = [];
      this.isRunning = false;
    },

    onRowSelected(items) {
      this.selected = items;
    },
  },
};
</script>

<style lang="sass">
.automatics-page-header + .table-responsive
  max-height: calc(100vh - 186px)
  margin-bottom: 0
  padding-bottom: 3rem

table#tableAutomaton
  font-size: 14px
  thead th,
  thead
    z-index: 2
  th[aria-colindex="6"]
    min-width: 100px
  th[aria-colindex="9"],
  th[aria-colindex="10"],
  th[aria-colindex="11"]
    min-width: 70px
  [aria-colindex="7"]:not(th),
  [aria-colindex="8"]:not(th),
  [aria-colindex="9"]:not(th),
  [aria-colindex="10"]:not(th),
  [aria-colindex="11"]:not(th),
  [aria-colindex="12"]:not(th),
  [aria-colindex="13"]:not(th),
  [aria-colindex="14"]:not(th),
  [aria-colindex="15"]:not(th),
  [aria-colindex="16"]:not(th)
    white-space: nowrap
    text-align: right
  thead th:nth-child(n+7)
    //min-width: 100px
    text-align: right

  th, td
    padding: 4px
    overflow: hidden
    text-overflow: ellipsis
    word-break: break-word

  div.resizable
    white-space: nowrap
    overflow: auto
    overflow-x: hidden
    text-overflow: ellipsis
    min-width: 70px
    button.ml-2
      margin-left: 0 !important

  div.resizable:nth-child(3)
    min-width: 100px
  div.resizable:nth-child(4)
    min-width: 150px

  thead:hover div.resizable
    resize: horizontal

  tr
    td:first-child,
    td:nth-child(2),
    td:nth-child(5),
    td:nth-child(6)
      max-width: 70px
    td:nth-child(3)
      max-width: 100px
    td:nth-child(4)
      max-width: 150px
</style>
